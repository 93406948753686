import React, { useState, useEffect } from "react";
import "./email-logs-modal.scss";
import { fetchEmailLogs } from "../../../http-calls";
import moment from "moment";

const EmailLogsModal = ({ isVisible, onClose, registration }) => {
  const [emailLogs, setEmailLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isVisible && registration) {
      loadEmailLogs();
    }
  }, [isVisible]);

  const loadEmailLogs = async () => {
    setLoading(true);
    try {
      const response = await fetchEmailLogs({ registrationId: registration["Reg Id"] });
      setEmailLogs(response.data.emailLogs || []);
    } catch (error) {
      console.error("Failed to fetch email logs:", error);
    }
    setLoading(false);
  };

  if (!isVisible) return null;

  return (
    <div className="emailLogsModal show">
      <div className="overlay"></div>
      <div className="modalContainer centered-modal">
        <div className="modalHeader">
          <h3>Email History</h3>
          <span className="close" onClick={onClose}>
            <i className="fa fa-times"></i>
          </span>
        </div>
        <div className="modalContent"
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <div className="registrationDetails">
            <p><strong>Name:</strong> {registration["User Name"]}</p>
            <p><strong>Email:</strong> {registration["User Email"]}</p>
            <p><strong>Event:</strong> {registration["Event Name"]}</p>
            <p><strong>Event Date:</strong> {registration["Event Date"]}</p>
          </div>
          {loading ? (
            <p>Loading...</p>
          ) : emailLogs.length > 0 ? (
            <table className="emailLogsTable">
              <thead>
                <tr>
                  <th>Sent On</th>
                  <th>Hours</th>
                  <th>Notes</th>
                  <th>Link</th>
                </tr>
              </thead>
              <tbody>
                {emailLogs.map((log, index) => (
                  <tr key={log.id}>
                    <td>{moment(parseInt(log.sent_on)).format("MM/DD/YYYY hh:mm A")}</td>
                    <td>{log.hours}</td>
                    <td>{log.notes || "N/A"}</td>
                    <td>
                      <a href={log.cert_link} target="_blank" rel="noopener noreferrer">
                        View
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No email logs found.</p>
          )}
        </div>
        <div className="modalFooter">
          <button className="btn btn-secondary" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailLogsModal;
