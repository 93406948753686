import React, { useState, useEffect } from "react";
import "./bulk-email-request-modal.scss";
import { store } from "../../../redux/store";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import ManageEventHelper from "../../helpers/manage-events-helper";
import { sendBulkEmails } from "../../../http-calls";
import moment from "moment";

const BulkEmailRequestModal = ({
  isVisible = false,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const [years, setYears] = useState([]);
  const [events, setEvents] = useState([]);
  const [slots, setSlots] = useState([]);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedSlot, setSelectedSlot] = useState("");
  const [emailOption, setEmailOption] = useState("sent");

  useEffect(() => {
    fetchAllEvents();
  }, []);

  const fetchAllEvents = async () => {
    store.dispatch(showLoader("Loading events..."));
    try {
      const { data } = await ManageEventHelper.getAllEventsWithSlots();
      const formattedEvents = ManageEventHelper.categorisedEvents(data);
      setYears(formattedEvents.map((item) => item.year));
      setEvents(formattedEvents);
    } catch (error) {
      console.error("Failed to fetch events:", error);
    }
    store.dispatch(hideLoader());
  };

  const handleYearChange = (e) => {
    const year = e.target.value;
    setSelectedYear(year);
    setSelectedEvent("");
    setSelectedSlot("");
    setSlots([]);
  };

  const handleEventChange = (e) => {
    const eventId = e.target.value;
    setSelectedEvent(eventId);
    const yearData = events.find((item) => item.year === selectedYear);
    const selectedEventObj = yearData?.events.find(
      (event) => event.event_id === eventId
    );
    setSelectedSlot("");
    if (!selectedEventObj?.slots) {
      setSlots([]);
      return;
    }
    const formattedSlots = selectedEventObj.slots.map((slot) => ({
      ...slot,
      start_date: moment(slot.start_date, "DD/MM/YYYY").format("MM/DD/YYYY"),
      end_date: moment(slot.end_date, "DD/MM/YYYY").format("MM/DD/YYYY"),
    }));
    setSlots(formattedSlots || []);
  };

  const handleSlotChange = (e) => {
    setSelectedSlot(e.target.value);
  };

  const handleSubmit = async () => {
    if (!selectedSlot) {
      alert("Please select a slot before submitting.");
      return;
    }

    const payload = {
      event_slot_id: selectedSlot,
      force: emailOption === "not_sent",
    };

    try {
      store.dispatch(showLoader("Sending bulk email requests..."));
      const { data } = await sendBulkEmails(payload);
      alert("Bulk email request sent successfully.");
      onSubmit(data);
    } catch (error) {
      alert(error.message || "Something went wrong during submission.");
    } finally {
      store.dispatch(hideLoader());
    }
  };

  if (!isVisible) return null;

  return (
    <div className="bulkEmailRequestModal show">
      <div className="overlay" onClick={onCancel}></div>
      <div className="modalContainer">
        <div className="modalHeader">
          <h3>
            <b>Send Bulk Email Request</b>
          </h3>
          <span className="close" onClick={onCancel}>
            <i className="fa fa-times"></i>
          </span>
        </div>

        <div className="modalContent">
          <div className="form-group">
            <label>Select Year</label>
            <select
              className="form-control"
              value={selectedYear}
              onChange={handleYearChange}
            >
              <option value="" disabled>
                Select a year
              </option>
              {years.map((year) => (
                <option key={year} value={year}>{`${
                  parseInt(year) - 1
                } - ${year}`}</option>
              ))}
            </select>
          </div>

          {selectedYear && (
            <div className="form-group">
              <label>Select Event</label>
              <select
                className="form-control"
                value={selectedEvent}
                onChange={handleEventChange}
              >
                <option value="" disabled>
                  Select an event
                </option>
                {events
                  .find((item) => item.year === selectedYear)
                  ?.events.map((event) => (
                    <option key={event.event_id} value={event.event_id}>
                      {event.event_name}
                    </option>
                  ))}
              </select>
            </div>
          )}

          {selectedEvent && (
            <div className="form-group">
              <label>Select Slot</label>
              <select
                className="form-control"
                value={selectedSlot}
                onChange={handleSlotChange}
              >
                <option value="" disabled>
                  Select a slot
                </option>
                {slots.map((slot) => (
                  <option key={slot.event_slot_id} value={slot.event_slot_id}>
                    {`${slot.start_date} ${slot.start_time} - ${slot.end_time}`}
                  </option>
                ))}
              </select>
            </div>
          )}
          {selectedSlot ? (
            <div className="form-group">
              <label>Recipient Selection</label>
              <div className="radio-group">
                <input
                  type="radio"
                  id="sent"
                  name="emailOption"
                  value="sent"
                  checked={emailOption === "sent"}
                  onChange={() => setEmailOption("sent")}
                />
                <label htmlFor="sent">
                  Send to all whose certificate is generated
                </label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  id="not_sent"
                  name="emailOption"
                  value="not_sent"
                  checked={emailOption === "not_sent"}
                  onChange={() => setEmailOption("not_sent")}
                />
                <label htmlFor="not_sent">
                  Send to those whose certificate is generated but not sent yet
                </label>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="modalFooter text-end mt-4">
          <button className="btn btn-secondary me-2" onClick={onCancel}>
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={!selectedSlot}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default BulkEmailRequestModal;
