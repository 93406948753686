import React, { Component } from "react";
import { connect } from "react-redux";
import EventRegisterPrompt from "../../components/event-register-prompt/event-register-prompt";
import {
  getAllFocus,
  getAllRegisteredEvents,
  getAllUpcomingEvents,
} from "../../http-calls";
import { hideLoader, showLoader } from "../../redux/actions/loader-data";
import "./registered-events-page.scss";
import moment from "moment";
import { convert24To12 } from "../../helper-methods";
import EventViewer from "../../components/event-viewer/event-viewer";
import EventsTabbedview from "../../components/events-tabbed-view/events-tabbed-view";

class RegisteredEventsPage extends Component {
  state = {
    allEvents: [],
    myEventSlots: [],
    allFocus: [],
  };

  componentDidMount() {
    this._loadData();
  }

  _formatEvents = (sortedEvents) => {
    return sortedEvents.map((event) => {
      event.formattedSpeakers = {};
      event.speakers.forEach((speaker) => {
        if (!event.formattedSpeakers[speaker.day]) {
          event.formattedSpeakers[speaker.day] = [];
        }
        event.formattedSpeakers[speaker.day].push(speaker);
      });
      return event;
    });
  };

  _loadData = async () => {
    try {
      this.props.showLoader();
      let {
        data: { events: allEventSlots },
      } = await getAllUpcomingEvents();
      // allEventSlots = allEventSlots.filter(
      //   (event) => event.start_date.indexOf("2023") > -1
      // );
      let {
        data: { registeredEvents: registeredEventsSlots },
      } = await getAllRegisteredEvents();
      // registeredEventsSlots = registeredEventsSlots.filter(
      //   (event) => event?.start_date?.indexOf("2023") > -1
      // );
      const {
        data: { focus: allFocus },
      } = await getAllFocus();

      const allCurrentYearEvents = allEventSlots.filter(
        (event) => event.start_date.indexOf("2025") > -1 || event.start_date.indexOf("2024") > -1
      );
      const allEvents = {};
      allCurrentYearEvents.forEach((eventSlot) => {
        if (
          !allEvents[
            `${eventSlot.event_id}+${eventSlot.start_date}+${eventSlot.end_date}`
          ]
        ) {
          allEvents[
            `${eventSlot.event_id}+${eventSlot.start_date}+${eventSlot.end_date}`
          ] = {
            eventName: eventSlot.event_name,
            speakers: eventSlot.speakers,
            description: eventSlot.description,
            eventId: eventSlot.event_id,
            eventId: eventSlot.event_id,
            featuredSponsorId: eventSlot.featured_sponsor_id,
            isEnabled: eventSlot.isEnabled === 1,
            // joinUrl: eventSlot.joinURL,
            startDate: eventSlot.start_date,
            endDate: eventSlot.end_date,
            certLink: null,
            slots: [],
            sponsors: eventSlot.sponsors,
            hasAnyRegisteredSlots: false,
          };
        }
        const slot = {
          startTime: eventSlot.start_time,
          endTime: eventSlot.end_time,
          slotId: eventSlot.event_slot_id,
          alreadyRegistered: false,
        };
        const isAlreadyRegistered = registeredEventsSlots.find(
          (s) => s.event_slot_id === eventSlot.event_slot_id
        );
        console.log("isAlreadyRegistered", isAlreadyRegistered);
        slot.alreadyRegistered = !!isAlreadyRegistered;
        if (!!isAlreadyRegistered) {
          slot.joinUrl = isAlreadyRegistered.joinURL;
          slot.certLink = isAlreadyRegistered.cert_link;
        }
        if (
          !allEvents[
            `${eventSlot.event_id}+${eventSlot.start_date}+${eventSlot.end_date}`
          ].hasAnyRegisteredSlots
        ) {
          allEvents[
            `${eventSlot.event_id}+${eventSlot.start_date}+${eventSlot.end_date}`
          ].hasAnyRegisteredSlots = !!isAlreadyRegistered;
        }
        slot.focusName = isAlreadyRegistered?.focus;
        allEvents[
          `${eventSlot.event_id}+${eventSlot.start_date}+${eventSlot.end_date}`
        ].slots.push(slot);
      });
      console.log("allEvents :>> ", allEvents);
      const idMappedFocus = {};
      allFocus.forEach((focus) => {
        idMappedFocus[focus.focus_id] = focus;
      });
      const filteredEvents = Object.values(allEvents).filter(
        (e) => e.hasAnyRegisteredSlots
      );
      console.log("filteredEvents", filteredEvents);
      const formattedEvents = this._formatEvents(filteredEvents);
      console.log("formattedEvents :>> ", formattedEvents);
      this.setState({ allEvents: formattedEvents, allFocus: idMappedFocus });
    } catch (error) {
      console.log("error :>> ", error);
    }
    this.props.hideLoader();
  };

  render() {
    const { allEvents, allFocus } = this.state;

    console.log("allEvents 32222221:>> ", allEvents);

    return (
      <>
        <EventsTabbedview
          allEvents={allEvents}
          onEventRegistrationSuccess={this._loadData}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(null, mapDispatchToProps)(RegisteredEventsPage);
