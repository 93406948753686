import React, { useState } from "react";
import { useEffect } from "react";
import { ProgressBar } from "react-loader-spinner";
import { showToast } from "../../../helper-methods";
import { generateCertificate, sendCertEmailApi } from "../../../http-calls";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./certificate-downloader.scss";
import useDeepCompareEffect from "use-deep-compare-effect";
import EmailLogsModal from "../email-logs-modal/email-logs-modal";

const CertificateDownloader = ({ registration }) => {
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [hours, setHours] = useState(0);
  const [isEmailLogsVisible, setIsEmailLogsVisible] = useState(false);


  useDeepCompareEffect(() => {
    const hours = parseInt(registration?.Hours);
    if (!isNaN(hours)) {
      setHours(hours);
    }
  }, [registration]);

  const _generateCertificate = async (hours, download = false) => {
    if (hours > 0) {
      try {
        setIsLoaderActive(true);
        if (registration["Reg Id"]?.length) {
          const response = await generateCertificate({
            registrationId: registration["Reg Id"],
            hours,
          });
          console.log('response :>> ', response);
          if (response?.link?.length) {
            if (download) {
              const link = document.createElement("a");
              link.href = response.link;
              link.download = `${registration["User Name"]}_certificate.pdf`;
              link.dispatchEvent(new MouseEvent("click"));
            } else {
              showToast("Certificate generated successfully", "success");
            }
          } else {
            showToast("Certificate cannot be generated", "error");
          }
        }
      } catch (error) {
        console.log("error :>> ", error);
        showToast("Certificate cannot be generated", "error");
      }
      setIsLoaderActive(false);
    }
  };

  const _downloadCert = () => {
    _generateCertificate(hours, true);
  };

  const _sendCertMail = async () => {
    setIsLoaderActive(true);
    if (registration["Reg Id"]?.length && registration["Certificate"]?.length) {
      const response = await sendCertEmailApi({
        registrationId: registration["Reg Id"],
      });
      showToast("Certificate sent successfully", "success");
      setIsLoaderActive(false);
    }
  };

  const _updateHours = (hours) => {
    console.log("hours :>> ", hours);
    setHours(hours);
    if (hours > 0) {
      _generateCertificate(hours);
    }
  };

  // const isDisabled = registration["Event Name"]?.indexOf("2024") > -1;
  // const isCurrentYear = registration["Event Name"]?.indexOf("2023") > -1;
  const isDisabled = false;
  const isDownloadDisabled = hours === 0;
  const isSendDisabled = !registration["Certificate"]?.length;

  return (
    <>
      <>
        <div className={`certDownloadWrapper ${isDisabled && "disable"}`}>
          {isLoaderActive ? (
            <>
              <div className="certLoaderWrapper">
                <ProgressBar
                  height="30"
                  width="30"
                  ariaLabel="progress-bar-loading"
                  wrapperStyle={{}}
                  wrapperClass="progress-bar-wrapper"
                  borderColor="#A52A2A"
                  barColor="#A52A2A"
                />
              </div>
            </>
          ) : (
            <>
              {registration["Reg Id"]?.length ? (
                <>
                  <div className="hourSelectorWrapper">
                    <select
                      name="hours"
                      id="hours"
                      value={hours}
                      onChange={(e) => _updateHours(e.target.value)}
                      disabled={isDisabled}
                    >
                      <option value="0" disabled>
                        0
                      </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                    </select>
                  </div>
                  <div
                    className="downloadBtn"
                    onClick={_downloadCert}
                    style={
                      isDownloadDisabled
                        ? { opacity: 0.5, cursor: "not-allowed" }
                        : {}
                    }
                  >
                    <i className="fas fa-download"></i>
                  </div>
                  {registration["Certificate"] &&
                  registration["Certificate"]?.length &&
                  hours > 0 ? (
                    <div className="downloadBtn" onClick={_sendCertMail}>
                      <i className="fas fa-envelope"></i>
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* Another button to show email history */}
                    <div className="emailHistoryShowBtn" onClick={e => {
                      setIsEmailLogsVisible(true);
                    }}>
                      <i className="fas fa-history"></i>
                    </div>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </>
      <EmailLogsModal
        isVisible={isEmailLogsVisible}
        onClose={() => setIsEmailLogsVisible(false)}
        registration={registration}
      />
    </>
  );
};

export default CertificateDownloader;
